import React from "react"
import styled from "styled-components"
import QRContainer from "../components/QRContainer/index"

const Container = styled.div`
  display: flex;
  width: 100%;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 2rem;

  h2 {
    font-size: 1.8rem;
    font-weight: 600;
    @media (max-width: 479px) {
      font-size: 1.2rem;
      text-align: center;
    }
  }
  a,
  button {
    outline: none;
    padding: 15px 20px;
    cursor: pointer;
    text-decoration: none;
  }
  a {
    background-color: var(--thirdColor);
    border: none;
    color: #ffffff;
    margin-right: 1rem;
  }
  button {
    border: none;
    background-color: #dfdfdf;
    color: #000;
  }
`

function ScanMe() {
  return (
    <Container>
      <QRContainer />
    </Container>
  )
}

export default ScanMe
