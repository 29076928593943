import { navigate } from "gatsby"
import React, { useState } from "react"
import { QrReader } from "react-qr-reader"

const Test = (props) => {
  const [data, setData] = useState("")
  const constraints = {
    facingMode: "environment",
  }
  const handleClickNo = () => {
    setData("")
  }
  return (
    <>
      {!data && (
        <QrReader
          constraints={constraints}
          containerStyle={{ width: 300, margin: "auto" }}
          onResult={(result, error) => {
            if (!!result) {
              setData(result?.text)
            }

            if (!!error) {
              console.info(error)
            }
          }}
          style={{ width: "100%" }}
        />
      )}
      {data.indexOf(".pdf") !== -1 ? (
        <h2>
          Your'e about to be redirected to download a{" "}
          <span style={{ color: "var(--thirdColor)" }}>PDF</span> file.
        </h2>
      ) : (
        <h2>
          Your'e about to be redirected to{" "}
          <span style={{ color: "var(--thirdColor)" }}>{data}</span>.
        </h2>
      )}

      {data && <p>Do you wish to be redirected?</p>}
      {data && (
        <div className="links_to_redirect">
          <a href={data} target="_blank">
            Yes
          </a>
          <button onClick={handleClickNo}>No</button>
        </div>
      )}
      {/* <p style={{ color: "#fff" }}>{data && (window.location.href = data)}</p> */}
    </>
  )
}

export default Test
